import React, { useState, useEffect } from "react";
import MultiSelect from "react-multi-select-component";
import styled from "styled-components";
import { BoxMultiselect as BaseBoxMultiselect } from "../../admin_ui/Form";
import { axios } from "../../../request/settings";

const overrideStrings = {
  selectSomeItems: "Selecciona",
  allItemsAreSelected: "Todos los elementos están seleccionados..",
  selectAll: "Seleccionar todo",
  search: "Buscar",
  clearSearch: "Borrar búsqueda",
};

const StyledBoxMultiselect = styled(BaseBoxMultiselect)`
  .dropdown-content .item-renderer {
    margin: 20px;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  /* Estilos base para las categorías padre */
  .dropdown-content .item-renderer:not([data-parent-id]) {
    margin-left: 10px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
  }

  /* Estilos para las subcategorías (elementos con data-parent-id) */
  .dropdown-content .item-renderer[data-parent-id] {
    font-size: 0.95em;
    color: #666;
    margin-left: 40px;
    background-color: #f9f9f9;
  }

  /* Estilos para hover y elementos con checkbox */
  .dropdown-content .item-renderer:hover {
    background-color: #e9ecef;
  }

  .dropdown-content .item-renderer input[type="checkbox"] {
    margin-right: 8px;
  }
`;

const SelectCategories1 = ({ categories, initialSelectedCategories = [], onCategoryChange }) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const optionsCategory = categories
    .map((e) => ({ label: e.name, value: e.id }))
    .filter((elm) => elm.label !== "cupones");

  useEffect(() => {
    const loadInitialSubcategories = async () => {
      if (initialSelectedCategories.length > 0) {
        setSelectedCategory(initialSelectedCategories);
        
        const processedIds = new Set();
        const newSubCategories = [];
        
        for (const category of initialSelectedCategories) {
          const subs = await fetchSubCategories(category.value, 1, processedIds);
          newSubCategories.push(...subs);
        }
        
        // Eliminar duplicados basados en el value
        const uniqueSubCategories = Array.from(
          new Map(newSubCategories.map(item => [item.value, item])).values()
        );
        
        setSubCategories(uniqueSubCategories);
      }
    };

    loadInitialSubcategories();
  }, [initialSelectedCategories]);

  const fetchSubCategories = async (categoryId, level = 1, processedIds = new Set()) => {
    try {
      if (processedIds.has(categoryId)) {
        return [];
      }
      processedIds.add(categoryId);

      const response = await axios.get(`/categories/sub_categories?id=${categoryId}`);
      return response.data.map(cat => ({
        label: `${'↳'.repeat(level)} ${cat.name}`,
        value: cat.id,
        parentId: categoryId,
        level: level,
        hasChildren: true
      }));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const handleCategoryChange = async (selected) => {
    const lastChanged = selected.length > selectedCategory.length
      ? selected.find(sel => !selectedCategory.find(prev => prev.value === sel.value))
      : selectedCategory.find(prev => !selected.find(sel => sel.value === prev.value));

    if (lastChanged) {
      if (selected.length > selectedCategory.length) {
        const newSubCategories = await fetchSubCategories(lastChanged.value, 1);
        
        // Combinar subcategorías evitando duplicados
        const combinedSubCategories = [...subCategories];
        newSubCategories.forEach(newSub => {
          if (!combinedSubCategories.some(existing => existing.value === newSub.value)) {
            combinedSubCategories.push(newSub);
          }
        });
        
        setSubCategories(combinedSubCategories);
        setSelectedCategory(selected);
        onCategoryChange(selected);
      } else {
        const categoriesToRemove = new Set([lastChanged.value]);
        
        const findChildCategories = (parentId) => {
          const directChildren = subCategories.filter(sub => sub.parentId === parentId);
          
          directChildren.forEach(child => {
            categoriesToRemove.add(child.value);
            findChildCategories(child.value);
          });
        };

        findChildCategories(lastChanged.value);

        const newSelected = selected.filter(cat => 
          !categoriesToRemove.has(cat.value)
        );

        setSelectedCategory(newSelected);
        
        setSubCategories(prev => 
          prev.filter(sub => !categoriesToRemove.has(sub.value))
        );

        onCategoryChange(newSelected);
      }
    } else {
      setSelectedCategory(selected);
      onCategoryChange(selected);
    }
  };

  const sortOptionsWithSubcategories = (categories, subcategories) => {
    const sortedOptions = [];
    const processedIds = new Set();
    
    const addCategoryWithChildren = (category, level = 0) => {
      if (processedIds.has(category.value)) {
        return;
      }
      processedIds.add(category.value);
      
      // Categoría padre sin espacios
      const categoryWithLevel = {
        ...category,
        label: level > 0 
          ? `${'\u00A0'.repeat(level * 6)}${'▸'.repeat(level)} ${category.label.replace(/↳/g, '')}`
          : category.label,
        level: level
      };
      sortedOptions.push(categoryWithLevel);
      
      // Buscar y ordenar los hijos
      const children = subcategories
        .filter(sub => sub.parentId === category.value)
        .sort((a, b) => a.label.replace(/↳/g, '').localeCompare(b.label.replace(/↳/g, '')));

      children.forEach(child => {
        addCategoryWithChildren(child, level + 1);
      });
    };

    categories.forEach(category => {
      addCategoryWithChildren(category);
    });
    
    return sortedOptions;
  };

  // Nueva función para limpiar el formato de las etiquetas
  const cleanLabel = (label) => {
    return label
      .replace(/\u00A0/g, '') // Eliminar espacios no rompibles
      .replace(/▸/g, '')      // Eliminar flechas
      .trim();                // Eliminar espacios extras
  };

  return (
    <StyledBoxMultiselect className="form-group">
      <label>
        <strong>Categorías seleccionadas:</strong>
      </label>
      <div className="selected-categories mb-3">
        <div className="mt-2">
          {selectedCategory.map((category) => (
            <span
              key={category.value}
              className="badge bg-primary me-2 mb-2"
              style={{ fontSize: '0.9em' }}
            >
              {cleanLabel(category.label)}
            </span>
          ))}
        </div>
      </div>
      <MultiSelect
        options={sortOptionsWithSubcategories(optionsCategory, subCategories)}
        value={selectedCategory}
        onChange={handleCategoryChange}
        labelledBy={"Selecciona las Categorías de la oferta"}
        className="multiselects"
        overrideStrings={overrideStrings}
        itemRenderer={({ checked, option, onClick, disabled }) => (
          <div
            className="item-renderer"
            data-level={option.level || 0}
            data-parent-id={option.parentId}
            onClick={onClick}
            style={{ width: '100%' }}
          >
            <input
              type="checkbox"
              onChange={() => {}}
              checked={checked}
              disabled={disabled}
              className="mr-2"
            />
            <span>
              {option.label}
              {option.hasChildren && !checked && " ▸"}
            </span>
          </div>
        )}
      />
    </StyledBoxMultiselect>
  );
};

export default SelectCategories1;
