import React, { useState } from 'react';
import styled from '@emotion/styled';
import Image from "next/image";
import ModalAlert from '../../admin/ModalAlert';
import MenuOffers from './MenuOffers';
import MenuCoupons from './MenuCoupons';
import MenuComments from './MenuComments';
import ModalOfferFast from '../../admin/ModalOfferFast';
import ModalOfferFast2 from '../../admin/ModalOfferFast2';
import { getPendingCounts } from "@context/commentsContext";

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: auto;
  height: 65px;
  padding: 0 25px;
  transition: all 0.3s ease;
  background: white;
  margin-left: ${props => props.menuDynamic ? "0px" : "270px"};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin-left: 0;
    grid-template-columns: 1fr 2fr;
    padding: 0 15px;
  }
`;

const BoxLeft = styled.div`
  grid-column: 1;
  align-self: center;
  display: flex;
  align-items: center;
`;

const BoxRight = styled.div`
  grid-column: 2;
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 10px;
  width: auto;
`;

const SpanLink = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
  background-image: url("/statics/img/icon_menu/more.png");
  background-size: cover;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  
  &:hover {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    filter: ${(props) => (props.menuDynamic ? "" : "invert(100%)")};
    transform: translateX(-15px);
    
    &:hover {
      transform: translateX(-15px) scale(1.05);
    }
  }
`;

const SpanTitle = styled.span`
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const BoxAlert = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  
  img {
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 5px;
    
    &:hover {
      transform: scale(1.05);
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  
  button {
    background: transparent;
    border: none;
    padding: 8px 8px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateY(-1px);
    }
  }
`;

const Header = ({ menuView, menuDynamic }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openOffers, setOpenOffers] = useState(false);
  const [openCoupons, setOpenCoupons] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [openOfferFast, setOpenOfferFast] = useState(false);
  const [openOfferFast2, setOpenOfferFast2] = useState(false);
  const [countAlerts, setCountAlerts] = useState(0);
  const { pendingComments, offerCount, couponCount } = getPendingCounts();

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleOpenEditOffers = () => {
    setOpenOffers(true);
  };

  const handleOpenEditCoupon = () => {
    setOpenCoupons(true);
  };

  const handleOpenEditComments = () => {
    setOpenComments(true);
  };


  const handleOpenOfferFast = () => {
    setOpenOfferFast(true);
  };

  const handleOpenOfferFast2 = () => {
    setOpenOfferFast2(true);
  };

  return (
    <Nav menuDynamic={menuDynamic}>
      <BoxLeft className="display-6 m-4 p-0 pb-4">
        <SpanLink onClick={menuView} menuDynamic={menuDynamic}></SpanLink>
        <SpanTitle>Dashboard</SpanTitle>
      </BoxLeft>
      <BoxRight>
        <BoxAlert className="m-4 ms-0 d-flex align-items-center justify-content-center">
          {/* //test de offer fast for user 7, 1714 and 1690 with rezise */}
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenOfferFast}>
            <Image src="/statics/img/icon_menu/temu.png" style={{ display: 'inline-block', background: 'transparent' }} alt="alerts" width={30} height={30} />
          </button>
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenOfferFast2}>
            <Image src="/statics/img/icon_menu/amazonLogo.png" style={{ display: 'inline-block', background: 'transparent' }} alt="alerts" width={30} height={30} />
          </button>
          {/* //offer fast */}
          {/* <button type="button" className="btn btn-light position-relative me-3" onClick={handleOpenOfferFast}>
            <Image src="/statics/img/icon_menu/limited-time-offer.png" style={{ display: 'inline-block', background: 'white' }} alt="alerts" width={30} height={30} />
          </button> */}
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenEditOffers}>
            <Image src="/statics/img/icon_menu/etiqueta-de-precio.png" style={{ display: 'inline-block', background: 'transparent' }} alt="alerts" width={25} height={25} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{offerCount === 0 ? null : offerCount}</span>
          </button>
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenEditCoupon}>
            <Image src="/statics/img/icon_menu/cupon.png" style={{ display: 'inline-block', background: 'transparent' }} alt="alerts" width={25} height={25} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{couponCount === 0 ? null : couponCount}</span>
          </button>
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenEditComments}>
            <Image src="/statics/img/icon_menu/conversacion.png" style={{ display: 'inline-block', background: 'transparent' }} alt="alerts" width={25} height={25} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{pendingComments === 0 ? null : pendingComments}</span>
          </button>
          <button type="button" className="btn btn-light position-relative" onClick={handleOpenEdit}>
            <Image src="/statics/img/warning.png" style={{ display: 'inline-block', background: 'transparent' }} alt="alerts" width={25} height={25} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: '10px'}}>{countAlerts === 0 ? null : countAlerts}</span>
          </button>
        </BoxAlert>
      </BoxRight>
      <ModalAlert open={openEdit} setOpen={setOpenEdit} setCountAlerts={setCountAlerts}/>
      <MenuOffers open={openOffers} setOpen={setOpenOffers} />
      <MenuCoupons open={openCoupons} setOpen={setOpenCoupons} />
      <MenuComments open={openComments} setOpen={setOpenComments} />
      <ModalOfferFast open={openOfferFast} setOpen={setOpenOfferFast} />
      <ModalOfferFast2 open={openOfferFast2} setOpen={setOpenOfferFast2} />
    </Nav>
  );
}

export default Header;