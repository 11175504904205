import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import FormOfferFast from "./offers/FormOfferFast";
import styled from '@emotion/styled';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  position: absolute;
  width: 90%;
  max-width: 750px;
  max-height: 90vh;
  background: white;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    &:hover {
      background: #999;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const Alert = styled.div`
  background: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const Form = styled.form`
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  font-size: 0.9rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3ead47;
    box-shadow: 0 0 0 2px rgba(62, 173, 71, 0.1);
  }

  &::placeholder {
    color: #999;
  }
`;

const Button = styled.button`
  background: linear-gradient(135deg, #3ead47, #70cd38);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(62, 173, 71, 0.2);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  padding: 0.5rem;
  background: #fff5f5;
  border-radius: 4px;
`;

const LoadingMessage = styled.h3`
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 2rem 0;
`;

const ModalOfferFast2 = ({ open, setOpen }) => {
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => {
    setOpen(false);
    setUrl("");
    setTitle("");
    setImage(null);
    setError("");
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setError("");
  };

  const isAmazonUrl = (url) => {
    return /^https?:\/\/(www\.)?(amazon\.(com|es|de|co\.uk|fr|it|ca|com\.mx)|amzn\.to|a\.co|amzn\.com)(\/.*)?$/.test(url);
  };

  const fetchImageAsFile = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "scraped_image.jpg", { type: blob.type });
      return file;
    } catch (error) {
      console.error("Error downloading image:", error);
      setError("Error al descargar la imagen.");
    }
  };

  const fetchData = async () => {
    if (!url) {
      setError("La URL no puede estar vacía");
      return;
    }
    if (!isAmazonUrl(url)) {
      setError("Por favor, ingresa un enlace válido de Amazon");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`/api/scrapeData2?url=${encodeURIComponent(url)}`);
      setTitle(response.data.title);
      if (response.data.image) {
        const imageFile = await fetchImageAsFile(response.data.image);
        setImage(imageFile);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error al obtener los datos.");
      setTitle("");
      setImage(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url && open) {
      fetchData();
    }
  }, [url, open]);

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContent>
        <Title>Ingresar URL para crear oferta rápida Amazon</Title>
        <Alert role="alert">
          Antes de crear ofertas rápidas por favor revisar las variables de entorno con la tienda y categoría estén
          configuradas...
        </Alert>
        
        <Form onSubmit={(e) => e.preventDefault()}>
          <Input
            type="text"
            value={url}
            onChange={handleUrlChange}
            placeholder="Pega aquí la URL..."
          />
          <Button type="button" onClick={fetchData} disabled={loading || !url}>
            Obtener datos
          </Button>
        </Form>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {loading ? (
          <LoadingMessage>Cargando...</LoadingMessage>
        ) : (
          title &&
          image && <FormOfferFast titleOffer={title} urlOffer={url} imageOffer={image} handleClose={handleClose} />
        )}
      </ModalContent>
    </StyledModal>
  );
};

export default ModalOfferFast2;
