import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import FormOfferFastTemu from "./offers/FormOfferFastTemu";
import styled from '@emotion/styled';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  position: relative;
  width: 90%;
  max-width: 750px;
  max-height: 90vh;
  background: white;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  overflow-y: auto;
  margin: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    &:hover {
      background: #999;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const Alert = styled.div`
  background: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const Form = styled.form`
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  font-size: 0.9rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3ead47;
    box-shadow: 0 0 0 2px rgba(62, 173, 71, 0.1);
  }

  &::placeholder {
    color: #999;
  }
`;

const Button = styled.button`
  background: linear-gradient(135deg, #3ead47, #70cd38);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(62, 173, 71, 0.2);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  padding: 0.5rem;
  background: #fff5f5;
  border-radius: 4px;
`;

const LoadingMessage = styled.h3`
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 2rem 0;
`;

const ModalOfferFast = ({ open, setOpen }) => {
  const [url, setUrl] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setUrl("");
    setImage(null);
    setError("");
  };

  const fetchData = async (urlToFetch) => {
    setError("");
    setImage(null);
    setTitle("");
    setLoading(true);

    try {
      const response = await axios.get(`/api/scrapeData?url=${encodeURIComponent(urlToFetch)}`);
      const { image: imageData, title: titleData } = response.data;

      if (imageData) {
        const imageFile = await fetchImageAsFile(imageData);
        if (imageFile) {
          setImage(imageFile);
          setTitle(titleData);
        } else {
          throw new Error("Error al procesar la imagen");
        }
      } else {
        throw new Error("No se encontró la imagen");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error al obtener los datos de Temu. Verifica que la URL sea correcta.");
      setImage(null);
      setTitle("");
    } finally {
      setLoading(false);
    }
  };

  const handleUrlChange = async (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setError("");

    if (!newUrl) {
      setImage(null);
      return;
    }

    if (!isTemuUrl(newUrl)) {
      setError("Por favor, ingresa un enlace válido de Temu");
      return;
    }

    await fetchData(newUrl);
  };

  const handleButtonClick = async () => {
    if (!url) {
      setError("Por favor, ingresa una URL");
      return;
    }

    if (!isTemuUrl(url)) {
      setError("Por favor, ingresa un enlace válido de Temu");
      return;
    }

    await fetchData(url);
  };

  const isTemuUrl = (url) => {
    return /^https?:\/\/(www\.|app\.)?(temu\.com)(\/.*)?$/.test(url);
  };

  const fetchImageAsFile = async (imageUrl) => {
    try {
      // Para imágenes en base64
      if (imageUrl.startsWith('data:image')) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new File([blob], "scraped_image.jpg", { type: blob.type });
      }
      // Para URLs normales
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], "scraped_image.jpg", { type: blob.type });
    } catch (error) {
      console.error("Error downloading image:", error);
      return null;
    }
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent>
        <Title>Ingresar URL para crear oferta rápida Temu</Title>
        <Alert role="alert">
          Antes de crear ofertas rápidas por favor revisar las variables de entorno con la tienda y categoría estén
          configuradas para Temu...
        </Alert>
        
        <Form>
          <Input
            type="text"
            value={url}
            onChange={handleUrlChange}
            placeholder="Pega aquí la URL de Temu..."
          />
          <Button 
            type="button" 
            onClick={handleButtonClick} 
            disabled={loading || !url}
          >
            {loading ? 'Obteniendo datos...' : 'Reintentar obtener datos'}
          </Button>
        </Form>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {loading ? (
          <LoadingMessage>Cargando datos de Temu...</LoadingMessage>
        ) : (
          image && (
            <FormOfferFastTemu 
              urlOffer={url} 
              imageOffer={image} 
              titleOffer={title}
              handleClose={handleClose} 
            />
          )
        )}
      </ModalContent>
    </StyledModal>
  );
};

export default ModalOfferFast;
