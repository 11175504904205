import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { useUser } from "@context/userContext";
import { getPendingCounts } from "@context/commentsContext";
import { useMenuAccess } from "@context/MenuContext";

const Box = styled.section`
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  width: 270px;
  background-color: #222;
  color: #ffffff;
  transition: all 0.3s ease;
  transform: translateX(${props => props.menuDynamic ? "-270px" : "0px"});
`;

const BoxLeft = styled.div`
  position: absolute;
  grid-column: 1;
  align-self: center;
  display: none;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const IconLink = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
  background-image: url("/statics/img/icon_menu/more.png");
  filter: invert(100%);
  background-size: cover;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  
  &:hover {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const BoxTitle = styled.div`
  width: 100%;
  padding: 20px;
  padding-bottom: 0px;
  background: #222;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-bottom: 0px;
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #333;
`;

const ProfileImage = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid #333;
  object-fit: cover;
  background: white;
`;

const ProfileName = styled.h3`
  font-size: 1.2rem;
  margin: 5px 0;
  color: white;
  text-align: center;
`;

const ProfileRole = styled.span`
  font-size: 0.9rem;
  color: #888;
  text-align: center;
`;

const BoxMenu = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const BoxUl = styled.ul`
  overflow: auto;
  height: calc(100vh - 180px);
  padding: 10px;
  margin: 0;
  list-style: none;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #333;
    border-radius: 10px;
  }
`;

const BoxLink = styled.a`
  display: flex;
  align-items: center;
  padding: ${props => props.menuDynamic ? "12px 8px" : "12px 16px"};
  text-decoration: none;
  color: #888;
  transition: all 0.3s ease;
  border-radius: 10px;
  background: ${props => props.active ? 'linear-gradient(to right, #2c2c2c, #1a1a1a)' : 'transparent'};

  &:hover {
    background: linear-gradient(to right, #2c2c2c, #1a1a1a);
    color: white;
    transform: translateX(5px);
  }

  span:last-child {
    display: ${props => props.menuDynamic ? "none" : "inline"};
    margin-left: 10px;
    font-size: 14px;
  }
`;

const SpanLink = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.menuDynamic ? "24px" : "30px"};
  height: ${props => props.menuDynamic ? "24px" : "30px"};
  margin-right: ${props => props.menuDynamic ? "0" : "10px"};
  background-image: url(${(props) => `/statics/img/icon_menu/${props.image}`});
  background-size: cover;
  filter: ${(props) => (props.invertImage ? "invert(0%)" : "invert(100%)")};
  opacity: ${props => props.active ? 1 : 0.7};
  transition: all 0.3s ease;
`;

const BadgeBox = styled.span`
  min-width: 20px;
  height: 22px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  border-radius: 10px;
  background: linear-gradient(135deg, #ff3e3e, #ff6b6b);
  color: white;
  padding: 2px 6px;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(255, 62, 62, 0.3);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(255, 62, 62, 0.4);
  }
`;

const TitleMenu = styled.div`
  padding: 15px 20px;
  margin: 10px 0;
  color: #666;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: 10px;
    width: 30px;
    height: 2px;
    background: linear-gradient(to right, #444, #222);
    border-radius: 2px;
  }

  &:before {
    content: '⚡';
    margin-right: 8px;
    font-size: 14px;
    color: #666;
  }
`;

const SideNav = ({ menuView, role, menuDynamic }) => {
  const { user } = useUser();
  const { accessMenus } = useMenuAccess();
  const [namePage, setNamePage] = useState('principal')
  const { pendingComments, offerCount, couponCount } = getPendingCounts();
  const router = useRouter();
  const verifiedMenu = (name, id) => {
    const findMenu = accessMenus.find(({ name: menuName }) => menuName === name);
    return findMenu && findMenu.access.includes(id.toString());
  };

  const handleclick = () => {
    if(window.innerWidth < 768){
      menuView()
    }
  }
  
  useEffect(() => {
    const panelFromUrl = router.pathname.split('/')[2];
    if (router.pathname === '/') {
      setNamePage('principal');
    } else {
      setNamePage(panelFromUrl);
    }
  }, [router.pathname]);

  return (
    <Box menuDynamic={menuDynamic}>
      <BoxLeft className="display-6 m-4 p-0 pb-4">
        <IconLink onClick={menuView}></IconLink>
      </BoxLeft>
      <BoxTitle>
        <ProfileSection>
          <ProfileImage 
            src={user.avatar ? user.avatar : "/statics/img/512x512.png"} 
            alt="Profile" 
            menuDynamic={menuDynamic}
          />
          <ProfileName>{user.username}</ProfileName>
          <ProfileRole>{user.email}</ProfileRole>
        </ProfileSection>
      </BoxTitle>
      <BoxMenu menuDynamic={menuDynamic}>
        <TitleMenu menuDynamic={menuDynamic}>Menu</TitleMenu>
        <BoxUl>
          <li>
            <Link href="https://www.ofertu.co/" passHref>
              <BoxLink href="Pagina Ofertu Principal">
                <SpanLink image="192x192.png" invertImage={true}></SpanLink>
                <span> Ir a Ofertu.co</span>
              </BoxLink>
            </Link>
          </li>
          <li>
            <Link href="/" passHref>
              <BoxLink href="Menu Principal" onClick={handleclick} style={namePage === 'principal' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                <SpanLink image="libro-abierto.png"></SpanLink>
                <span>Pagina principal</span>
              </BoxLink>
            </Link>
          </li>
          {role === "superadmin" &&
            <li>
              <Link href="/admin/panel" passHref >
                <BoxLink href="Panel Control" onClick={handleclick} style={namePage === 'panel' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="control.png"></SpanLink>
                  <span> Panel de control</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("ofertas", user.id)) &&
            <li>
              <Link href="/admin/offers" passHref>
                <BoxLink href="Menu_principal" onClick={handleclick} style={namePage === 'offers' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="etiqueta-de-precio.png"></SpanLink>
                  <span>Ofertas</span>
                  {offerCount > 0 ? <BadgeBox>{offerCount}</BadgeBox> : null}
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("cupones", user.id)) &&
            <li>
              <Link href="/admin/coupons" passHref>
                <BoxLink href="Cupones" onClick={handleclick} style={namePage === 'coupons' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="cupon.png"></SpanLink>
                  <span>Cupones</span>
                  {couponCount > 0 ? <BadgeBox>{couponCount}</BadgeBox> : null}
                </BoxLink>
              </Link>
            </li>
          }
          {
            (role === "superadmin" || verifiedMenu("forum", user.id)) &&
              <li>
                <Link href="/admin/forum" passHref>
                  <BoxLink href="Forum" style={namePage === 'forum' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                    <SpanLink image="chat.png"></SpanLink>
                    <span>Foro</span>
                    {pendingComments > 0 ? <BadgeBox>{pendingComments}</BadgeBox> : null}
                  </BoxLink>
                </Link>
              </li>
          }
          {(role === "superadmin" || verifiedMenu("comentarios", user.id)) &&
            <li>
              <Link href="/admin/comments" passHref>
                <BoxLink href="Comentarios" onClick={handleclick} style={namePage === 'comments' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="conversacion.png"></SpanLink>
                  <span>Comentarios</span>
                  {pendingComments > 0 ? <BadgeBox>{pendingComments}</BadgeBox> : null}
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("tiendas", user.id)) &&
            <li>
              <Link href="/admin/shops" passHref>
                <BoxLink href="Tiendas" onClick={handleclick} style={namePage === 'shops' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="carro.png"></SpanLink>
                  <span>Tiendas</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("categorias", user.id)) &&
            <li>
              <Link href="/admin/category" passHref>
                <BoxLink href="Categorias" onClick={handleclick} style={namePage === 'category' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="categorias.png"></SpanLink>
                  <span>Categorias</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("crear editor", user.id)) &&
            <li>
              <Link href="/admin/form_editor" passHref>
                <BoxLink href="Crear Editor" onClick={handleclick} style={namePage === 'form_editor' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="usuario.png"></SpanLink>
                  <span>Crear Editor</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("crear oferta", user.id)) &&
            <li>
              <Link href="/admin/create_offer" passHref>
                <BoxLink href="Crear Oferta" onClick={handleclick} style={namePage === 'create_offer' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="etiqueta-de-precio.png"></SpanLink>
                  <span>Crear Oferta</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("crear cupon", user.id)) &&
            <li>
              <Link href="/admin/create_coupon" passHref>
                <BoxLink href="Crear Cupon" onClick={handleclick} style={namePage === 'create_coupon' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="cupon.png"></SpanLink>
                  <span>Crear Cupon</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("usuarios", user.id)) &&
            <li>
              <Link href="/admin/users" passHref>
                <BoxLink href="Usuarios" onClick={handleclick} style={namePage === 'users' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="grupo-de-usuarios.png"></SpanLink>
                  <span>Usuarios</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("banners", user.id)) &&
            <li>
              <Link href="/admin/banners" passHref>
                <BoxLink href="Banners" onClick={handleclick} style={namePage === 'banners' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="digital.png"></SpanLink>
                  <span>Banners</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("concurso", user.id)) &&
            <li>
              <Link href="/admin/raffle" passHref>
                <BoxLink href="Concurso" onClick={handleclick} style={namePage === 'raffle' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="premio.png"></SpanLink>
                  <span>Concurso</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("top tiendas", user.id)) &&
            <li>
              <Link href="/admin/top_shops" passHref>
                <BoxLink href="Top Tiendas" onClick={handleclick} style={namePage === 'top_shops' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="tienda.png"></SpanLink>
                  <span>Top Tiendas</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("interacciones", user.id)) &&
            <li>
              <Link href="/admin/users-interactive" passHref>
                <BoxLink href="Interacciones Usuarios" onClick={handleclick} style={namePage === 'users-interactive' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="interacciones.png"></SpanLink>
                  <span>Interacciones</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("analiticas", user.id)) &&
            <li>
              <Link href="/admin/analytical" passHref>
                <BoxLink href="Analytical" onClick={handleclick} style={namePage === 'analytical' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="analitico.png"></SpanLink>
                  <span>Analiticas</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("blog", user.id)) &&
            <li>
              <Link href="/admin/blog" passHref>
                <BoxLink href="Blog" onClick={handleclick} style={namePage === 'blog' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="blog.png"></SpanLink>
                  <span>Blog</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("multicodigos", user.id)) &&
            <li>
              <Link href="/admin/multicode" passHref>
                <BoxLink href="Multicode" onClick={handleclick} style={namePage === 'multicode' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="referral-code.png"></SpanLink>
                  <span>Multicodigos</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("edicion", user.id)) &&
            <li>
              <Link href="/admin/edit" passHref>
                <BoxLink href="Edit" onClick={handleclick} style={namePage === 'edit' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="pencil.png"></SpanLink>
                  <span>Edicion</span>
                </BoxLink>
              </Link>
            </li>
          }
          <li style={{ height: "80px" }}></li>
          <li style={{ height: "80px" }}></li>
          <li style={{ height: "80px" }}></li>
          <li style={{ height: "80px" }}></li>
        </BoxUl>
      </BoxMenu>
    </Box>
  );
};

export default SideNav;
