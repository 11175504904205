import React, { useState, useEffect } from "react";
import { axios } from "../../../request/settings";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const SearchContainer = styled.div`
  margin-bottom: 20px;
  
  .search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    &:focus {
      outline: none;
      border-color: #0d6efd;
      box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
    }
  }
`;

const CategoriesList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;

  .category-item {
    margin: 5px 0;
    padding: 5px;
    transition: background-color 0.2s;
    
    &:hover {
      background-color: #f8f9fa;
    }
  }

  .no-results {
    text-align: center;
    padding: 20px;
    color: #666;
  }
`;

const CategoryItem = styled.div`
  margin: 5px 0;
  padding: 5px;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #f8f9fa;
  }

  &[data-level="1"] {
    margin-left: 0;
  }

  &[data-level="2"] {
    margin-left: 20px;
  }

  &[data-level="3"] {
    margin-left: 40px;
  }

  &[data-level="4"] {
    margin-left: 60px;
  }
`;

const SelectCategories2 = ({ categories, initialSelectedCategories = [], onCategoryChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hierarchyInfo, setHierarchyInfo] = useState(new Map());

  useEffect(() => {
    if (initialSelectedCategories.length > 0) {
      setSelectedCategories(initialSelectedCategories);
    }
  }, [initialSelectedCategories]);

  useEffect(() => {
    const loadHierarchyInfo = async () => {
      const hierarchyMap = new Map();
      
      // Cargar información para las categorías inicialmente seleccionadas
      for (const category of initialSelectedCategories) {
        try {
          const response = await axios.get(`/categories/search_tree?query=${category.label}`);
          response.data.forEach(item => {
            hierarchyMap.set(item.category.id, {
              ...item.category,
              parents_tree: item.parents_tree
            });
          });
        } catch (error) {
          console.error("Error loading hierarchy info:", error);
        }
      }
      
      setHierarchyInfo(hierarchyMap);
    };

    if (initialSelectedCategories.length > 0) {
      loadHierarchyInfo();
    }
  }, [initialSelectedCategories]);

  const processSearchResults = (results) => {
    const processedResults = [];
    
    results.forEach(item => {
      const { category, parents_tree } = item;
      
      // Filtrar la categoría "casillero" o "lockerDefault"
      if (category.name.toLowerCase() !== 'casillero' && 
          category.name.toLowerCase() !== 'lockerdefault') {
        processedResults.push({
          id: category.id,
          name: category.name,
          level: category.level,
          parents_tree: parents_tree,
          path: parents_tree 
            ? [...parents_tree.map(p => p.name).reverse(), category.name].join(" > ")
            : category.name
        });
      }
    });

    return processedResults.sort((a, b) => {
      if (a.level === b.level) {
        return a.name.localeCompare(b.name);
      }
      return a.level - b.level;
    });
  };

  const searchCategories = async (query) => {
    if (query.trim() === "") {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`/categories/search_tree?query=${query}`);
      const processedResults = processSearchResults(response.data);
      setSearchResults(processedResults);
    } catch (error) {
      console.error("Error searching categories:", error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounce la búsqueda para evitar demasiadas llamadas a la API
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchCategories(searchTerm);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handleCategoryChange = (category, fromBadge = false) => {
    let newSelectedCategories;
    const isSelected = selectedCategories.some(cat => cat.value === category.id);

    if (isSelected || fromBadge) {
      // Encontrar la información completa de la categoría
      let categoryInfo = fromBadge 
        ? searchResults.find(result => result.id === category.id)
        : category;

      if (categoryInfo) {
        const categoryLevel = categoryInfo.level;
        const categoryPath = categoryInfo.path;

        // Filtrar las categorías seleccionadas
        newSelectedCategories = selectedCategories.filter(cat => {
          // Buscar información completa de la categoría en los resultados
          const catInfo = searchResults.find(result => result.id === cat.value);
          
          if (!catInfo) {
            // Si no encontramos la info en los resultados de búsqueda,
            // intentar construir el path desde las categorías seleccionadas
            const selectedPaths = searchResults
              .filter(r => selectedCategories.some(sc => sc.value === r.id))
              .map(r => r.path);

            // Si la categoría es parte de algún path que comienza con la categoría a remover,
            // entonces es una hija y debe ser removida
            return !selectedPaths.some(path => 
              path.startsWith(categoryPath) && path.includes(cat.label)
            );
          }

          // Si tenemos la info de la categoría, verificar si es hija
          // Una categoría es hija si:
          // 1. Su nivel es mayor que la categoría que estamos deseleccionando
          // 2. Su path comienza con el path de la categoría que estamos deseleccionando
          const isChildCategory = 
            catInfo.level > categoryLevel && 
            catInfo.path.startsWith(categoryPath);

          // Mantener la categoría solo si NO es la que estamos deseleccionando
          // y NO es una categoría hija
          return !(cat.value === category.id || isChildCategory);
        });
      } else {
        // Si no podemos encontrar la info de la categoría, intentar usar el path
        const selectedPaths = searchResults
          .filter(r => selectedCategories.some(sc => sc.value === r.id))
          .map(r => r.path);

        const categoryLabel = category.name || category.label;
        
        newSelectedCategories = selectedCategories.filter(cat => {
          // Encontrar si esta categoría es parte de un path que incluye la categoría a remover
          const isPartOfPath = selectedPaths.some(path => 
            path.includes(categoryLabel) && 
            path.includes(cat.label) &&
            path.indexOf(categoryLabel) < path.indexOf(cat.label)
          );

          return !isPartOfPath && cat.value !== category.id;
        });
      }
    } else {
      // Si se está seleccionando, agregar esta categoría y todos sus padres
      const categoriesToAdd = [];
      
      // Agregar la categoría seleccionada
      categoriesToAdd.push({
        label: category.name,
        value: category.id
      });

      // Si la categoría tiene parents_tree, usarlo para agregar los padres
      if (category.parents_tree) {
        category.parents_tree.forEach(parent => {
          if (!selectedCategories.some(cat => cat.value === parent.id)) {
            categoriesToAdd.push({
              label: parent.name,
              value: parent.id
            });
          }
        });
      } else {
        // Si no tiene parents_tree, usar el path para encontrar los padres
        const pathParts = category.path.split(" > ");
        pathParts.pop();

        pathParts.forEach((parentName, index) => {
          const parentCategory = searchResults.find(cat => 
            cat.name === parentName && cat.level === index + 1
          );

          if (parentCategory && !selectedCategories.some(cat => cat.value === parentCategory.id)) {
            categoriesToAdd.push({
              label: parentCategory.name,
              value: parentCategory.id
            });
          }
        });
      }

      // Combinar las categorías existentes con las nuevas, evitando duplicados
      const existingIds = selectedCategories.map(cat => cat.value);
      const newCategories = categoriesToAdd.filter(cat => !existingIds.includes(cat.value));
      
      newSelectedCategories = [...selectedCategories, ...newCategories];
    }

    // Ordenar las categorías por nivel
    newSelectedCategories.sort((a, b) => {
      const categoryA = searchResults.find(cat => cat.id === a.value) || { level: 999 };
      const categoryB = searchResults.find(cat => cat.id === b.value) || { level: 999 };
      return (categoryA.level || 0) - (categoryB.level || 0);
    });

    setSelectedCategories(newSelectedCategories);
    onCategoryChange(newSelectedCategories);
  };
  
  return (
    <div>
      <SearchContainer>
        <input
          type="text"
          className="search-input"
          placeholder="Buscar categorías..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>

      <div className="selected-categories mb-3">
        <strong>Categorías seleccionadas:</strong>
        <div className="mt-2">
          {selectedCategories.map((category) => (
            <span
              key={category.value}
              className="badge bg-primary me-2 mb-2"
              style={{ fontSize: '0.9em' }}
            >
              {category.label}
              {/* <button
                type="button"
                className="btn-close btn-close-white ms-2"
                style={{ fontSize: '0.5em' }}
                onClick={() => handleCategoryChange(
                  { id: category.value, name: category.label },
                  true // Indicar que viene del badge
                )}
              ></button> */}
            </span>
          ))}
        </div>
      </div>

      <CategoriesList>
        {loading ? (
          <div className="text-center p-3">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </div>
        ) : searchResults.length > 0 ? (
          searchResults.map((category) => (
            <CategoryItem 
              key={category.id} 
              data-level={category.level}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCategories.some(cat => cat.value === category.id)}
                    onChange={() => handleCategoryChange(category)}
                    disabled={category.name.toLowerCase() === 'casillero' || 
                            category.name.toLowerCase() === 'lockerdefault'}
                    color="primary"
                  />
                }
                label={
                  <div>
                    <div style={{
                      color: (category.name.toLowerCase() === 'casillero' || 
                            category.name.toLowerCase() === 'lockerdefault') ? '#999' : 'inherit'
                    }}>
                      {category.name}
                    </div>
                    <small className="text-muted" style={{ fontSize: '0.8em' }}>
                      {category.path}
                    </small>
                  </div>
                }
              />
            </CategoryItem>
          ))
        ) : searchTerm ? (
          <div className="no-results">
            No se encontraron resultados
          </div>
        ) : null}
      </CategoriesList>
    </div>
  );
};

export default SelectCategories2;
